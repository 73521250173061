<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Perfil</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                @click="openDialog = true"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="groupUsers"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="removeGroup(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="800px">
        <v-card elevation="0">
          <v-card-title>
            <span class="headline">Perfil de Acesso</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="groupUser.systemClientId"
                    :items="companies"
                    item-text="fantasyName"
                    item-value="id"
                    label="Empresa"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nome"
                    v-model="groupUser.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="permissions"
                    v-model="groupUser.permissions"
                    label="Permissões"
                    item-text="label"
                    item-value="id"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Fechar
            </v-btn>
            <v-btn
              :loading="loadingAction"
              color="success"
              @click="createGroup()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import UserDialog from "@/components/UserDialog";
import http from "@/services/http";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  name: "GroupUsers",
  components: { UserDialog, InternalMenu, PageHeader },
  data: () => ({
    title: "Perfís de Acesso",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Usuários",
            to: "/users",
            permission: "super_admin",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Usuários",
        disabled: false,
        href: "/users",
      },
      {
        text: "Perfís de Acesso",
        disabled: true,
        href: "/user/group-users",
      },
    ],
    groupUsers: [],
    permissions: [],
    companies: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    groupUser: {
      name: "",
      systemClientId: null,
      permissions: [],
    },
    openDialog: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Empresa",
        align: "start",
        value: "systemClientId",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    getGroups() {
      this.loading = true;
      http.get("user/group-users").then((data) => {
        this.groupUsers = data.data;
        this.loading = false;
      });
    },
    removeGroup(itemId) {
      http.delete(`user/group-users/${itemId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Removido com Sucesso!";
          this.snackbar.opened = true;
          this.loadingAction = false;
          this.getGroups();
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text = "Falha ao remover registro " + err.response;
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    getCompanies() {
      http.get("tenants").then((data) => {
        this.companies = data.data;
      });
    },
    getPermissions() {
      http.get("permissions").then((data) => {
        this.permissions = data.data;
        this.permissions.map((d) => (d.label = `${d.name} | ${d.moduleLabel}`));
      });
    },
    createGroup() {
      this.loadingAction = true;
      if (this.groupUser.id) {
        http.put(`user/group-users/${this.groupUser.id}`, this.groupUser).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("user/group-users", this.groupUser).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
            this.getGroups();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.groupUser.name = null;
      this.groupUser.permissions = [];
      this.groupUser.systemClientId = null;
    },
    editItem(item) {
      this.groupUser = item;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getGroups();
    this.getPermissions();
    this.getCompanies();
  },
});
</script>