














































































/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "UseDialog",
  props: ["openDialog"],
  methods: {
    closeDialog() {
      this.$props.openDialog = false;
      location.reload();
    },
  },
});
